<template>
    <div
        :id="gridId"
        ref="gridElement"
        class="dx-grid-container theme-default">&nbsp;
    </div>
</template>

<script>
    import { useRqDxDataGrid } from "./composables";
    /*
     *  Visit the following URL for a complete list of possible dxDataGrid configuration options:
     *  https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/
     */
    export default {
        name: "RqDxDataGrid",
        props: {
            automation_id: { type: String, default: "" },
            persistState: { type: Boolean, default: false },
            config: { type: Object, default: null },
            dataSource: { default: () => [] },
            compactGrid: { type: Boolean, default: false },
            showRqLoadingIndicator: { type: Boolean, default: false }
        },

        setup() {
            const {
                gridId,
                gridInstance,
                gridElement,
                dxConfig,
                dxConfigReady,
                dxInitialized,
                dxLoaded,
                dxReady,
                componentMounted,
                selectedRowKeys
            } = useRqDxDataGrid();

            return {
                gridId,
                gridInstance,
                gridElement,
                dxConfig,
                dxConfigReady,
                dxInitialized,
                dxLoaded,
                dxReady,
                componentMounted,
                selectedRowKeys
            };
        },

        computed: {
            //Utilized within extended components (ActionDataGrid)
            persistGridState() { return this.persistState; }
        },

        watch: {
            config: {
                handler(val) {
                    if(!this.componentMounted) return;
                    this.updateConfig(val);
                    if(this.dxLoaded || !this.dxConfigReady) return;
                    this.loadGrid();
                },
                deep: true
            },
            dataSource(val) {
                if(this.dxInitialized){
                    this.dxConfig.dataSource = val;
                    this.gridInstance.option("dataSource", val);
                }
                else if(this.dxConfigReady) {
                    this.loadGrid(val);
                }
            }
        },

        created() {
            const self = this;
            self.setIdentifiers();
            self.setDefaultConfig();
            self.dxConfig = _.cloneDeep(self.defaultConfig);
        },

        mounted() {
            const self = this;
            self.componentMounted = true;
            self.updateConfig();
            if(!self.dxConfigReady) return;
            self.$nextTick(() => {
                self.loadGrid();
            });
        },

        beforeUnmount() {
            if(!this.gridInstance) return;
            this.gridInstance.hideColumnChooser();
        },

        methods: {

            setIdentifiers() {
                /* base instance "virtual" method */
            },

            getGridInstance() {
                let instance = $(this.gridElement).dxDataGrid("instance");
                if(!this.gridInstance && instance)
                    this.gridInstance = instance;
                return this.gridInstance;
            },

            setDefaultConfig() {
                const self = this;
                self.defaultConfig = {
                    columnChooser: {
                        sortOrder: "asc",
                        enabled: false
                    },
                    columns: [],
                    dataService: null,
                    editing: { mode: "form", texts: { confirmDeleteMessage: "" } },
                    wrapperAttr: { id: self.gridId, automation_id: self.automation_id },
                    excel: { enabled: false, fileName: "RQO-Export" },
                    headerFilter: { visible: false },
                    height: 455,
                    hoverStateEnabled: false,
                    loadPanel: {
                        enabled: false,
                        showIndicator: false,
                        showPane: false,
                        text: "",
                        // TG - Needs further research to find a viable solution that doesn't have adverse affects with $rqBusy
                        // onShowing() {
                        //     if(!self.showRqLoadingIndicator) return;
                        //     self.$rqBusy.startWait();
                        // },
                        // onHidden() {
                        //     if(!self.showRqLoadingIndicator) return;
                        //     self.$rqBusy.endWait();
                        // }
                    },
                    pager: {
                        showPageSizeSelector: true,
                        allowedPageSizes: [50, 100, 500],
                        showNavigationButtons: true,
                        showInfo: true
                    },
                    paging: { pageSize: 50 },
                    remoteOperations: { sorting: false, paging: false },
                    dataRowTemplate: null,
                    searchPanel: { visible: false },
                    selection: { mode: "none" },
                    sorting: { mode: "single" },
                    width: "100%",
                    showBorders: true,
                    scrolling: { showScrollbar: "always", useNative: true },
                    rowAlternationEnabled: true,
                    wordWrapEnabled: true,
                    onExporting(e) { e.fileName = self.config.excel.fileName; },
                    onRowUpdating(e) { self.$emit("rowUpdating", e); },
                    onRowUpdated(e) { self.$emit("rowUpdated", e); },
                    onFocusedRowChanged(e) { self.$emit("focusedRowChanged", e); }
                };
            },

            updateConfig(cfg=null) {
                const self = this;
                let sourceConfig = cfg || this.config;
                if(_.isNil(sourceConfig)) {
                    self.dxConfigReady = false;
                    return;
                }

                self.dxConfig = _.merge({}, self.defaultConfig, sourceConfig);
                self.dxConfig.dataSource = self.dataSource || [];

                if(_.isEmpty(self.dxConfig.columns)) return;

                //make sure we're handling these events internally first
                self.dxConfig.onContentReady = function(e) { self.onContentReady(e); };
                self.dxConfig.onInitialized = function(e) { self.onInitialized(e); };
                self.dxConfig.onRowDblClick = function(e) { self.onRowDblClick(e); };
                self.dxConfig.onCellClick = function(e) { self.onCellClick(e); };
                self.dxConfig.onRowClick = function(e) { self.onRowClick(e); };
                self.dxConfig.onKeyDown = function(e) { self.onKeyDown(e); };
                self.dxConfig.onSelectionChanged = function(e) { self.onSelectionChanged(e); };
                self.dxConfig.onCellPrepared = function(e) { self.onCellPrepared(e); };
                self.dxConfig.onEditorPreparing = function(e) { self.onEditorPreparing(e); };
                self.dxConfig.onRowValidating = function(e) { self.onRowValidating(e); };
                self.dxConfig.onRowPrepared = function(e) { self.onRowPrepared(e); };
                self.dxConfig.onDataErrorOccurred = function (e) {
                    console.error(e.error);
                    self.$rqBusy.endWait();
                    self.bubbleDxEvent("dataErrorOccurred", "onDataErrorOccurred", e);
                };

                //bypass default stateStoring setup if included in configuration
                if(_.isNil(self.dxConfig.stateStoring))
                    self.setStateStoring(sourceConfig);

                self.onConfigUpdateComplete(sourceConfig);
                self.dxConfigReady = true;
            },

            getStorageKey(cfg) {
                const self = this;
                let sourceConfig = cfg || this.config;

                let storageKey = sourceConfig.storageKey;
                let routeName = _.get(self, "$route.name", null);

                if (self.persistGridState && _.isNil(storageKey) && !_.isNil(self.automation_id) && !_.isNil(routeName)) {
                    storageKey = `${self.automation_id}::${routeName}`;
                }

                return storageKey;
            },

            setStateStoring(cfg=null) {
                const self = this;
                let storageKey = self.getStorageKey();

                if(_.isNil(storageKey)) return;
                const excludedProperties = [
                    "searchText",
                    "selectedRowKeys",
                    "filterValue",
                    "focusedRowKey",
                    "focusedRowIndex"
                ];
                self.dxConfig.stateStoring = {
                    enabled: true,
                    type: "custom",
                    storageKey,
                    customLoad() {
                        let state = localStorage.getItem(storageKey);
                        if(!state) return;
                        return _.omit(JSON.parse(state), excludedProperties);
                    },
                    customSave(state) {
                        localStorage.setItem(storageKey, JSON.stringify(state));
                    }
                };
            },

            loadGrid(ds=null) {
                if(ds) this.dxConfig.dataSource = ds;
                if(_.isNil(this.gridElement)) {
                    console.warn("RqDxDataGrid: gridElement unavailable...");
                    return;
                }
                this.gridInstance = $(this.gridElement)
                    .dxDataGrid(this.dxConfig)
                    .dxDataGrid("instance");
                this.gridInstance.updateDimensions();
                this.dxLoaded = true;
            },

            onInitialized(e) {
                const self = this;
                self.bubbleDxEvent("initialized", "onInitialized", e);
                self.dxInitialized = true;
            },

            onContentReady(e) {
                const self = this;
                self.selectedRowKeys = e.component.getSelectedRowKeys();
                self.bubbleDxEvent("contentReady", "onContentReady", e);
                self.dxReady = true;
            },

            onKeyDown(e) {
                const self = this;
                if(e.event.key === "Enter"){
                    self.$emit("enterKeyDown", e);
                }
                self.bubbleDxEvent("keyDown", "onKeyDown", e);
            },

            onRowDblClick(e) {
                const self = this;
                self.bubbleDxEvent("rowDoubleClick", "onRowDblClick", e);
            },

            onCellClick(e) {
                const self = this;
                self.bubbleDxEvent("cellClick", "onCellClick", e);
            },

            onRowClick(e) {
                const self = this;
                if(self.selectedRowKeys.length === 1 && self.selectedRowKeys[0] === e.key) {
                    e.component.deselectRows([e.key]);
                }
                self.selectedRowKeys = e.component.getSelectedRowKeys();
                self.bubbleDxEvent("rowClick", "onRowClick", e);
            },

            onSelectionChanged(e) {
                const self = this;
                self.bubbleDxEvent("selectionChanged", "onSelectionChanged", e);
            },

            onCellPrepared(e) {
                const self = this;
                self.bubbleDxEvent("cellPrepared", "onCellPrepared", e);
            },

            onRowPrepared(e) {
                const self = this;
                self.bubbleDxEvent("rowPrepared", "onRowPrepared", e);
            },

            onEditorPreparing(e) {
                const self = this;
                self.bubbleDxEvent("editorPreparing", "onEditorPreparing", e);
            },

            onRowValidating(e) {
                const self = this;
                self.bubbleDxEvent("rowValidating", "onRowValidating", e);
            },

            onConfigUpdateComplete(cfg=null) {
                //function called for extended components to override
            },

            bubbleDxEvent(vueEventName, cfgHandlerName, dxEvent) {
                const self = this;
                let cfgHandler = _.get(self, `config.${cfgHandlerName}`, null);
                if (_.isFunction(cfgHandler))
                    cfgHandler(dxEvent);
                else
                    self.$emit(vueEventName, dxEvent);
            }
        }
    };

</script>
