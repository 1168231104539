<template>
    <vue-final-modal
        ref="modalDialog"
        :id="dialogId"
        :name="dialogId"
        :resize="localOptions.resizable"
        :fit-parent="false"
        :drag-selector="localOptions.draggable ? `#${headerId}` : null"
        :drag="localOptions.draggable"
        :content-style="contentStyle"
        :min-height="localOptions.minHeight"
        :min-width="localOptions.minWidth"
        :click-to-close="localOptions.closeOnBackdrop"
        classes="vm--container"
        content-class="vm--modal"
        v-model="isShowing"
        @resize="onResize"
        @before-open="onBeforeOpen"
        @opened="onOpened"
        @before-close="onBeforeClose"
        @closed="onClosed">
        <div :class="['rq-modal-dialog', 'modal-dialog', this.localOptions.dialogStyle, this.themeClass]" :style="containerStyle" @keyup.enter="onEnter" @keyup.esc="onEsc">
            <div ref="modalContentElement" class="modal-content">
                <header v-if="localOptions.customHeader" :id="headerId" ref="headerElement" class="modal-header" :class="{ 'draggable': localOptions.draggable }"></header>
                <header v-else-if="!localOptions.hideHeader" :id="headerId" ref="headerElement" class="modal-header" :class="{ 'draggable': localOptions.draggable }">
                    <span v-if="localOptions.headerIcon"
                        class="modal-title"
                        :class="{ 'modal-title-lg': localOptions.titleSize === 'lg' }">
                        <FontAwesomeIcon :icon="localOptions.headerIcon" class="me-2" /> {{ localOptions.title }}
                    </span>
                    <span v-else
                        class="modal-title"
                        :class="{ 'modal-title-lg': localOptions.titleSize === 'lg' }"
                        v-html="localOptions.title">
                    </span>
                    <div class="modal-actions">
                        <template v-if="localOptions.showHeaderClose">
                            <button :id="`${dialogId}_close_modal_action`" type="button"
                                class="modal-action modal-action-close" aria-label="Close"
                                @click="modalCloseClick" @touchend="modalCloseClick"
                                ref="closeButton">
                                <span aria-hidden="true" class="fa fa-times"></span>
                            </button>
                            <b-tooltip v-model:show="closeTooltipVisible" :container="`#${dialogId}`" :target="`${dialogId}_close_modal_action`">Close/Cancel</b-tooltip>
                        </template>
                    </div>
                </header>
                <div class="modal-body" :style="modalBodyStyle">
                    <slot>
                        <component
                            ref="currentComponent"
                            :is="localOptions.component"
                            :key="componentKey"
                            v-bind="localOptions.props"
                            @dataUpdate="onDataUpdate"
                            @loaded="onComponentLoaded"
                            @action="onComponentAction"
                            @ok="onComponentOk"
                            @cancel="onComponentCancel"
                            @close-dialog="onComponentClose"
                            @enable-ok="onEnableOk"
                            @disable-ok="onDisableOk"
                            @set-button-state="onSetButtonState"
                            @update-dialog-options="onUpdateDialogOptions"
                            @revert-dialog-options="onRevertDialogOptions"
                            @use-custom-footer="onUseCustomFooter"
                            @hide-custom-footer="onHideCustomFooter">
                        </component>
                    </slot>
                </div>
                <footer v-if="localOptions.customFooter" :id="footerId" ref="footerElement" class="modal-footer"></footer>
                <footer v-else-if="!localOptions.hideFooter" :id="footerId" ref="footerElement" class="modal-footer">
                    <template v-if="hasCustomFooter">
                        <component
                            ref="componentFooter"
                            :is="customFooter"
                            v-bind="customFooterProps"
                            @ok="onCustomFooterOk"
                            @cancel="onCustomFooterCancel"
                            @close="onCustomFooterClose"
                        />
                    </template>
                    <template v-else-if="localOptions.buttons.length > 0">
                        <span v-for="btn in localOptions.buttons" :key="btn.clientKey" v-show="btn.isVisible" :id="`${btn.clientKey}-span`" :class="btn.spanClass">
                            <b-tooltip
                                v-if="btn.tooltip && btn.isVisible"
                                :target="`${btn.clientKey}-span`"
                                placement="top"
                                triggers="hover"
                                :container="$el"
                                :boundary="$el">
                                <span v-html="btn.tooltip"></span>
                            </b-tooltip>
                            <button type="button"
                                :automation_id="btn.automationId" :class="btn.cssClass" :disabled="btn.isDisabled || actionProcessing"
                                @click="onCustomButtonClick($event, btn.clientKey)">
                                <FontAwesomeIcon v-if="btn.iconClass" :icon="btn.iconClass" />{{btn.title}}
                            </button>
                        </span>
                    </template>
                    <template v-else>
                        <b-tooltip
                            v-if="!localOptions.okOnly && cancelTooltip"
                            :target="cancelContainerId"
                            placement="top"
                            triggers="hover"
                            :container="$el"
                            :boundary="$el">
                            <span v-html="cancelTooltip"></span>
                        </b-tooltip>
                        <b-tooltip
                            v-if="!localOptions.closeOnly && okTooltip"
                            :target="okContainerId"
                            placement="top"
                            triggers="hover"
                            :container="$el"
                            :boundary="$el">
                            <span v-html="okTooltip"></span>
                        </b-tooltip>

                        <span v-if="localOptions.showRequiredFieldLegend" class="required-legend"><span class="required-indicator">*</span> Field is Required</span>
                        <span v-if="!localOptions.okOnly" :id="cancelContainerId">
                            <button
                                ref="cancelButton"
                                :id="cancelId"
                                automation_id="btn_dm_modal_cancel"
                                type="button"
                                class="btn btn-secondary btn-cancel"
                                tabindex="0"
                                :disabled="actionProcessing"
                                @click="onCancel">{{localOptions.closeTitle}}
                            </button>
                        </span>
                        <span v-if="!localOptions.closeOnly" :id="okContainerId">
                            <button
                                ref="okButton"
                                :id="okId"
                                automation_id="btn_dm_modal_ok"
                                type="button"
                                class="btn btn-primary btn-ok"
                                tabindex="0"
                                :disabled="localOptions.okDisabled || actionProcessing"
                                @click="onOk"
                                v-focus="localOptions.autoFocusOkButton">{{localOptions.okTitle}}
                            </button>
                        </span>
                    </template>
                </footer>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
    import { ref, computed, onUpdated, nextTick, toRef } from "vue";
    import { RQDialogOptions } from "./models";

    export default {
        name: "RqDialog",
        props:{
            dialogOptions: { type:RQDialogOptions, required:true }
        },
        setup(props) {
            if (!props.dialogOptions.component) throw new Error("Dialog configuration requires a component property with a Vue instance.");

            const optProp = toRef(props, "dialogOptions");
            const localOptions = ref(new RQDialogOptions(optProp.value));
            const originalOptions = ref(new RQDialogOptions(optProp.value));

            const dialogId = ref(localOptions.value.dialogId);
            originalOptions.value.dialogId = dialogId.value;

            const parseStyleSize = val => {
                if(_.isNumber(val) || _.parseNumber(val) == val) return `${val}px`;
                if(!_.isString(val) || _.toLower(val) === "auto") return "auto";
                return val;
            };

            const componentState = {
                componentKey: ref(_.uniqueId()),
                isActive: ref(true),
                isShowing: ref(false),
                closedFromOkSuccess: ref(false),
                contentContainerHeight: ref(203),
                headerHeight: ref(0),
                footerHeight: ref(0),
                modalBodyStyle: ref(""),
                loadContainerStyle: ref(""),
                hasCustomFooter: ref(false),
                originalOptions: originalOptions,
                optionsUpdated: ref(false),
                componentLoading: ref(false),
                showLoadingIndicator: ref(false),
                currentDimensions: ref({ ready: false }),
                okTooltip: ref(""),
                cancelTooltip: ref(""),
                restoreTooltipVisible: ref(false),
                maximizeTooltipVisible: ref(false),
                closeTooltipVisible: ref(false),
                actionProcessing: ref(false),
                contentStyle: ref({
                    height: localOptions.value?.adaptive ? null : parseStyleSize(localOptions.value?.height),
                    width: parseStyleSize(localOptions.value?.width)
                }),
                customFooterProps: ref({}),
            };

            const computedIDs = {
                headerId: computed(() => `${dialogId.value}_header`),
                footerId: computed(() => `${dialogId.value}_footer`),
                okId: computed(() => `btn_ok_${dialogId.value}`),
                cancelId: computed(() => `btn_cancel_${dialogId.value}`),
                okContainerId: computed(() => `spn_ok_${dialogId.value}`),
                cancelContainerId: computed(() => `spn_cancel_${dialogId.value}`),
            };

            const headerElement = ref(null);
            const footerElement = ref(null);
            const headerAvailable = ref(false);
            const footerAvailable = ref(false);



            onUpdated(async () => {
                if(!headerAvailable.value && !_.isNil(headerElement.value)) {
                    await nextTick();
                    headerAvailable.value = true;
                }
                if(!footerAvailable.value && !_.isNil(footerElement.value)) {
                    await nextTick();
                    footerAvailable.value = true;
                }
            });

            return {
                localOptions,
                dialogId,
                headerElement,
                footerElement,
                headerAvailable,
                footerAvailable,
                ...componentState,
                ...computedIDs
            };
        },

        provide() {
            return {
                dialogId: this.dialogId,
                headerId: this.headerId,
                footerId: this.footerId,
                headerAvailable: computed(() => this.headerAvailable),
                footerAvailable: computed(() => this.footerAvailable)
            };
        },

        computed:{
            containerStyle() { return ""; },
            headerFooterHeight() { return this.headerHeight + this.footerHeight + 4; },
            contentComponentId() { return _.get(this, "$refs.currentComponent._uid", 0); },
            maximizeDisabled() { return !this.currentDimensions.ready || this.currentDimensions.maximized; },
            restoreDisabled() { return !this.currentDimensions.ready || this.currentDimensions.matches(this.originalOptions.height, this.originalOptions.width); },
            themeClass() { return this.localOptions.dialogTheme ? `modal-theme-${this.localOptions.dialogTheme}` : ""; }
        },

        mounted(){
            this.loadDialog();
        },

        methods: {

            loadDialog(){
                const self = this;
                self.setTooltips();
                self.$vfm.show(self.dialogId);
                self.$nextTick(() => {
                    self.setBodyStyle();
                });
            },

            onDataUpdate(eventArgs) {
                if (_.isFunction(this.localOptions.onDataUpdate))
                    this.localOptions.onDataUpdate(eventArgs);
            },

            modalCloseClick(e) {
                e.stopPropagation();
                e.preventDefault();
                this.close();
            },

            close() {
                if (!this.isShowing){
                    return;
                }
                this.$vfm.hide(this.dialogId);
            },

            setTooltips(okTooltipText=null, cancelTooltipText=null){
                const self = this;
                if(self.hasCustomFooter){
                    self.okTooltip = "";
                    self.cancelTooltip = "";
                }
                else {
                    let isValidTooltip = tt => !_.isNil(tt) && _.isString(tt);
                    self.okTooltip = isValidTooltip(okTooltipText)
                        ? okTooltipText
                        : isValidTooltip(self.localOptions.okTooltip)
                            ? self.localOptions.okTooltip
                            : "";
                    self.cancelTooltip = isValidTooltip(cancelTooltipText)
                        ? cancelTooltipText
                        : isValidTooltip(self.localOptions.cancelTooltip)
                            ? self.localOptions.cancelTooltip
                            : "";
                }
            },

            setTooltipsFromButtonEvent(e){
                this.setTooltips(
                    _.get(e, "okTooltip", null),
                    _.get(e, "cancelTooltip", null)
                );
            },

            handleOk(e){
                this.closedFromOkSuccess = true;
                this.showLoadingIndicator = this.localOptions.showLoadIndicatorOnOk;
                this.handleDialogClosingAction(e, this.localOptions.onOk);
            },

            handleClose(e){
                // Prevent native bubbled cancel events from closing dialog
                if(e?.srcElement instanceof HTMLInputElement && e?.type === "cancel") return;
                
                this.closedFromOkSuccess = false;
                let closeHandler = this.localOptions.onCancel || this.localOptions.onClose;
                this.handleDialogClosingAction(e, closeHandler);
            },

            handleCustomButtonAction(originalEvent, btn){
                const self = this;
                if(!btn) return;
                if(_.isFunction(btn.onClick)) {
                    if(btn.closeOnComplete)
                        self.handleDialogClosingAction(originalEvent, btn.onClick);
                    else
                        btn.onClick({ originalEvent, component: self.$refs.currentComponent });
                }
                else if(btn.closeOnComplete){
                    self.close();
                }
            },

            handleDialogClosingAction(originalEvent, handler){
                const self = this;
                let closeOnComplete = true;
                if(_.isFunction(handler)) {
                    let result = handler({ originalEvent, component: self.$refs.currentComponent });
                    if(_.isBoolean(result)){
                        closeOnComplete = result;
                        self.closedFromOkSuccess = result;
                        self.showLoadingIndicator = false;
                    }
                    else if(result && _.isFunction(result.then)){
                        self.actionProcessing = true;
                        closeOnComplete = false;
                        result
                            .then(canContinue => {
                                self.showLoadingIndicator = false;
                                self.actionProcessing = false;
                                if(canContinue)
                                    self.close();
                            })
                            .catch(() => {
                                self.showLoadingIndicator = false;
                                self.closedFromOkSuccess = false;
                                self.actionProcessing = false;
                            });
                    }
                }
                else
                    self.showLoadingIndicator = false;

                if(closeOnComplete)
                    self.close();
            },

            restoreDialogWindow() {
                const self = this;
                self.localOptions.height = self.originalOptions.height;
                self.localOptions.width = self.originalOptions.width;
                self.$nextTick(() => {
                    self.restoreTooltipVisible = false;
                    self.refreshDimensions();
                });
            },

            maximizeDialogWindow() {
                const self = this;
                self.localOptions.height = "100%";
                self.localOptions.width = "100%";
                self.$nextTick(() => {
                    self.maximizeTooltipVisible = false;
                    self.refreshDimensions();
                });
            },

            updateDimensions(){
                const self = this;
                if(self.$refs.modalContentElement) {
                    if(!self.localOptions.hideHeader && self.headerHeight === 0)
                        self.headerHeight = self.$refs.headerElement ? self.$refs.headerElement.clientHeight : 40;
                    if(!self.localOptions.hideFooter && self.footerHeight === 0)
                        self.footerHeight = self.$refs.footerElement ? self.$refs.footerElement.clientHeight : 56;
                    self.contentContainerHeight = self.$refs.modalContentElement.clientHeight - self.headerFooterHeight;

                    if(self.isShowing)
                        self.setBodyStyle();

                    self.loadContainerStyle = `top:${self.headerHeight+1}px; height:calc(100% - ${self.headerFooterHeight+2}px);`;
                }
                self.localOptions.args.contentContainerHeight = self.contentContainerHeight;
                if(self.$refs.currentComponent && _.isFunction(self.$refs.currentComponent.updateDimensions))
                    self.$refs.currentComponent.updateDimensions({ contentHeight:self.contentContainerHeight });
                if(_.isFunction(self.localOptions.onResize))
                    self.localOptions.onResize({ contentHeight:self.contentContainerHeight });

                // let modalInfo = _.get(self, "$refs.modalDialog.modal", {}) || {};
                // self.currentDimensions = new RQDialogDimensions(modalInfo);
            },

            setBodyStyle(){
                const self = this;

                let overflowValue = self.localOptions.overflowVisible
                    ? "visible"
                    : "hidden";

                self.modalBodyStyle = {
                    maxHeight: `calc(100vh - ${self.headerFooterHeight}px)`,
                    overflowY: self.localOptions.scrollable ? "auto" : overflowValue,
                    overflowX: overflowValue,
                };

                if(self.localOptions.adaptive) {
                    let minHeight = self.localOptions.minHeight;
                    if(_.isString(minHeight) && (_.includes(minHeight, "%") || _.includes(minHeight, "px"))) {
                        self.modalBodyStyle.minHeight = `calc(${minHeight} - ${self.headerFooterHeight}px)`;
                        return;
                    }

                    let minHeightTotal = minHeight - self.headerFooterHeight;
                    minHeightTotal = minHeightTotal < 80 ? 80 : minHeightTotal;
                    self.modalBodyStyle.minHeight = `${minHeightTotal}px`;
                }
                else {
                    self.modalBodyStyle.height = `calc(100% - ${self.headerFooterHeight}px)`;
                }
            },

            refreshDimensions() {
                const self = this;
                // self.$refs.modalDialog.setInitialSize();
                self.$nextTick(() => self.updateDimensions());
            },

            reloadComponent(updateProps=null) {
                const self = this;
                if(!_.isEmpty(updateProps)) {
                    _.merge(this.localOptions.props, updateProps);
                }
                this.componentKey = _.uniqueId();
            },

            onDisableOk(e){
                this.localOptions.okDisabled = true;
                this.setTooltipsFromButtonEvent(e);
            },

            onEnableOk(e){
                this.localOptions.okDisabled = false;
                this.setTooltipsFromButtonEvent(e);
            },

            onSetButtonState({ index=0, tooltip=null, disabled=false }) {
                if(_.isEmpty(this.localOptions.buttons)) {
                    if(index !== 0) return;
                    this.localOptions.okDisabled = disabled;
                    this.setTooltips(tooltip);
                }
                else if(this.localOptions.buttons.length >= index - 1) {
                    this.localOptions.buttons[index].isDisabled = disabled;
                    this.localOptions.buttons[index].tooltip = _.isNil(tooltip)
                        ? this.originalOptions.buttons[index].tooltip
                        : tooltip;
                }
            },

            onUpdateDialogOptions(options) {
                const self = this;
                _.merge(self.localOptions, options);
                if(self.isShowing){
                    self.optionsUpdated = true;
                }
                self.$nextTick(() => self.refreshDimensions());
            },

            onRevertDialogOptions() {
                const self = this;
                _.assign(this.localOptions, this.originalOptions);
                self.$nextTick(() => self.refreshDimensions());
            },

            onComponentLoaded(){
                this.componentLoading = false;
                this.showLoadingIndicator = false;
            },

            onOk(e) { this.handleOk(e); },

            onCancel(e) { this.handleClose(e); },

            onCustomFooterOk(e){ this.handleOk(e); },

            onCustomFooterCancel(e){ this.handleClose(e); },

            onCustomFooterClose(e){ this.handleClose(e); },

            onUseCustomFooter(e){
                if(!e.component) return;
                this.customFooter = e.component;
                this.customFooterProps = e.props || {};
                this.hasCustomFooter = true;
                this.setTooltips();
            },

            onHideCustomFooter(e){
                this.hasCustomFooter = false;
                this.setTooltips();
            },

            onComponentAction(e) { this.handleDialogClosingAction(e, this.localOptions.onComponentAction); },

            onComponentOk(e){ this.handleOk(e); },

            onComponentCancel(e){ this.handleClose(e); },

            onComponentClose(e){ this.close(); },

            onCustomButtonClick(e, btnKey){
                const self = this;
                let clickedBtn = _.find(self.localOptions.buttons, b => b.clientKey === btnKey);
                self.handleCustomButtonAction(e, clickedBtn);
            },

            onEnter(e){
                const self = this;
                if(self.localOptions.buttons.length > 0){
                    _.forEach(self.localOptions.buttons, b => {
                        if(b.triggerOnEnter){
                            self.handleCustomButtonAction(e, b);
                            return false;
                        }
                    });
                }
                else if(self.localOptions.triggerOkOnEnter){
                    self.handleDialogClosingAction(e, self.localOptions.onOk);
                }
            },

            onEsc(e){
                if(this.localOptions.closeOnEsc)
                    this.handleDialogClosingAction(e, this.localOptions.onCancel);
            },

            onResize(e){
                const self = this;
                let debouncedUpdateDimensions = _.debounce(self.updateDimensions, 150);
                debouncedUpdateDimensions();
            },

            onBeforeOpen(e) {
                //before shown
                this.updateDimensions();
                if(_.isFunction(this.localOptions.onBeforeOpen))
                    this.localOptions.onBeforeOpen();
                this.$emit("beforeOpen", this.dialogId);
            },

            onOpened(e) {
                //this.isShowing = true;

                /*
                    RQDialogOptions constructor sets "autoFocusFirstInput" to false if "autoFocusOkButton" is true and "okDisabled" is false;
                    "autoFocusOkButton" is set to whatever option is passed so that a warning can display if conflicting options exist
                */
                // this.$nextTick()
                //     .then(() => {
                //         this.setDialogElementFocus();
                //     });
                /************************************************************************************************************************/

                // if(this.optionsUpdated){
                //     this.$refs.modalDialog.setInitialSize();
                // }

                this.updateDimensions();

                if(_.isFunction(this.localOptions.onOpened))
                    this.localOptions.onOpened({ originalEvent: e, component: this.$refs.currentComponent });

                this.$emit("opened", this.dialogId);
            },

            onBeforeClose(e) {
                //this.isShowing = false;
                if(_.isFunction(this.localOptions.onBeforeClose))
                    this.localOptions.onBeforeClose({ originalEvent: e, component: this.$refs.currentComponent, cancelled:!this.closedFromOkSuccess });
                this.$emit("beforeClose", this.dialogId);
            },

            onClosed(e) {
                if(this.isActive) this.isActive = false;
                if(_.isFunction(this.localOptions.onClosed))
                    this.localOptions.onClosed({ cancelled:!this.closedFromOkSuccess });
                this.$emit("closed", this.dialogId);
            },

            // setDialogElementFocus() {
            //     if(this.localOptions.autoFocusOkButton) {
            //         this.focusOnOkButton();
            //     }
            //     else if(this.localOptions.autoFocusFirstInput) {
            //         this.focusOnFirstInputElement();
            //     }
            // },

            focusOnFirstInputElement() {
                let inputs = this.$refs.modalDialog.$el.querySelectorAll("input, select, textarea");
                if(inputs && inputs.length && inputs.length > 0){
                    _.forEach(inputs, input => {
                        if((!input.style || input.style.display !== "none") && !input.disabled && input.focus){
                            input.focus();
                            return false;
                        }
                    });
                }
                else if(inputs.focus)
                    inputs.focus();
            },

            focusOnOkButton() {
                let okBtnElement = _.get(this, "$refs.okButton", null);
                if(!okBtnElement || this.localOptions.okDisabled) {
                    console.warn("RqDialog -- Cannot auto-focus ok button while disabled or with custom button configuration.");
                }
                else {
                    okBtnElement.focus();
                }
            },
        },
    };
</script>