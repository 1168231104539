import { ref } from "vue";
import { DATA_TYPES, FILTER_TYPES } from "./enums";
import { RqColumnFilterOptions } from "./models";
import {
    getRangeFilterExpression,
    getListFilterExpression,
    getCombinedFilterExpression,
    applyFilterState
} from "./GridUtil";
import { useVModel } from "@vueuse/core";

export function useRqDxDataGrid() {
    const gridId = _.uniqueId("rq-data-grid-");
    const gridElement = ref(null);
    const gridInstance = ref(null);
    const dxConfig = ref({});
    const dxConfigReady = ref(false);
    const dxInitialized = ref(false);
    const dxLoaded = ref(false);
    const dxReady = ref(false);
    const componentMounted = ref(false);
    const selectedRowKeys = ref([]);

    return {
        gridId,
        gridInstance,
        gridElement,
        dxConfig,
        dxConfigReady,
        dxInitialized,
        dxLoaded,
        dxReady,
        componentMounted,
        selectedRowKeys
    };
}

//TODO: reconcile/integrate functionality w/ RqGridFilterPopover to leverage universally
export function useRqFilters(props, emit) {

    const gridFilterState = useVModel(props, "filterState", emit);
    const gridInstance = props.gridInstance;

    const isDateType = col => col?.dataType === DATA_TYPES.date;
    const isDateTimeType = col => col?.dataType === DATA_TYPES.datetime;
    const isStringType = col => col?.dataType === DATA_TYPES.string;
    const isNumberType = col => col?.dataType === DATA_TYPES.number;
    const isBoolType = col => col?.dataType === DATA_TYPES.boolean;
    const isDateFilter = col => isDateType(col) || isDateTimeType(col);

    const getConfiguredDataSource = (column, options) => options?.dataSource
        || column?.lookup?.dataSource
        || column?.lookup?.data
        || [];

    const getRqFilterOptions = dataField => {
        let cfgCols = gridInstance.option("columns");
        let col = _.find(cfgCols, { dataField });
        return new RqColumnFilterOptions(col?.rqFilter);
    };

    const parseFilterStateValue = obj => _.isNil(obj.value)
        ? _.isNil(obj.values)
            ? null
            : obj.values
        : obj.value;

    const getFilterType = (column, options=new RqColumnFilterOptions()) => {
        if(options.filterType !== FILTER_TYPES.default) return options.filterType;
        let ds = getConfiguredDataSource(column, options);
        return _.isEmpty(ds) ? options.filterType : FILTER_TYPES.tags;
    };

    const applyFilters = (freshApply=false) => applyFilterState({
        gridInstance,
        filterState: gridFilterState.value,
        freshApply
    });

    function getDataSource(column, options, items=[]) {
        let dataSource = getConfiguredDataSource(column, options);

        if(options.persistSelectedItems && _.isFunction(dataSource.load)) {
            let tbDataSource = _.cloneDeep(dataSource);
            tbDataSource.load = function(loadOptions) {
                let sanitizedItems = _.map(items, _.toPlainObject);
                return dataSource.load(loadOptions, sanitizedItems);
            };
            return tbDataSource;
        }

        return _.isFunction(dataSource)
            ? dataSource()
            : dataSource;
    }

    function getFilterValue(column) {
        let dataField = column?.dataField;
        if(!dataField) return;

        if(_.isEmpty(gridFilterState.value)) return;

        let result = { value: null, selected: [], useNumberRange: false };

        let fieldState = gridFilterState.value?.[dataField];
        let value = fieldState?.value;

        if(_.isNil(value) || (_.isEmpty(value) && _.isString(value))) return result;

        let useNumberRange = _.parseBool(fieldState.useNumberRange);

        if(isDateFilter(column) || useNumberRange) {
            let startValue = _.isArray(value) ? value[0] : value;
            let endValue = _.isArray(value) ? value[1] || null : null;
            result.value = [startValue, endValue];
            result.useNumberRange = useNumberRange;
        }
        else {
            result.value = value;
            result.selected = _.cloneDeep(fieldState?.selected)
        }
        return result;
    }

    function setFilterValue(column, value, isRange, operator=null, selected=null) {
        let newFilters = _.cloneDeep(gridFilterState.value);
        let dataField = column?.dataField;
        let filterOpts = getRqFilterOptions(dataField);
        let filterInfo = {};

        newFilters[dataField] = null;

        if(isRange) {
            filterInfo.filters = getRangeFilterExpression(column, value);
        }
        else if(_.isArray(value)) {
            filterInfo.filters = getListFilterExpression(column, value, filterOpts.listOperator, filterOpts.valueOperator);
        }
        else if(_.isNil(value) || (_.isString(value) && _.isEmpty(value)))
            filterInfo.filters = null;
        else {
            let opr = _.isNil(operator) ? _.isBoolean(value) ? "=" : "contains" : operator;
            filterInfo.filters = column.calculateFilterExpression(value, opr);
            filterInfo.value = value;
            filterInfo.filters.filterValues = value;
        }

        if(!_.isNil(filterInfo.filters)) {
            if(!_.isEmpty(selected)) {
                filterInfo.selected = selected;
            }
            newFilters[dataField] = filterInfo;
        }

        gridFilterState.value = _.omitBy(newFilters, _.isNil);
    }

    function parseFilterState(filterList) {
        if(!gridInstance) return null;
        let resultState = {};
        _.forEach(filterList, f => {
            let column = gridInstance?.columnOption(f.dataField);
            if(!column) return;
            let filterType = getFilterType(column, f.filterOptions);
            let value = parseFilterStateValue(f);
            resultState[f.dataField] = getColumnFilterState(column, value, filterType, f.selected, f.useNumberRange);
        });
        return _.omitBy(resultState, _.isNil);
    }

    function getFilterExpression() {
        let filters = getCombinedFilterExpression(gridFilterState.value);
        return _.isArray(filters) ? filters.slice() : [filters];
    }

    function getColumnFilterExpression(column, value, fieldType="", isRange=false) {
        let filterOpts = getRqFilterOptions(column?.dataField);
        if(isDateFilter(column) || isRange) {
            if(_.isEmpty(value) || _.every(value, _.isNil)) return [];
            return getRangeFilterExpression(column, value);
        }
        else if(fieldType === FILTER_TYPES.tags) {
            return getListFilterExpression(column, value, filterOpts?.listOperator, filterOpts?.valueOperator);
        }
        else if(isBoolType(column) && !_.isNil(value)) {
            return column.calculateFilterExpression(value, "=");
        }
        else if(_.isNumber(value) || !_.isEmpty(value)) {
            let operator = fieldType === "select" ? "=" : "contains";
            return column.calculateFilterExpression(value, operator);
        }
        return [];
    }

    function getColumnFilterState(column, value, fieldType="", items=[], useNumberRange=false) {
        let filters = getColumnFilterExpression(column, value, fieldType, useNumberRange);
        if(_.isEmpty(filters)) return null;
        if(isDateFilter(column)) {
            return {
                filters,
                value: filters.filterValues,
                selected: items.slice()
            };
        }
        else if(fieldType === FILTER_TYPES.tags) {
            return {
                filters,
                value: value.slice(),
                selected: items.slice()
            };
        }
        else if((isBoolType(column) && !_.isNil(value)) || _.isNumber(value) || !_.isEmpty(value)) {
            return {
                filters,
                value,
                useNumberRange
            };
        }
        return null;
    }

    function removeColumnFilter(dataField, applyResult=true) {
        removeColumnFilterList([dataField], applyResult);
    }

    function removeColumnFilterList(dataFields, applyResult=true) {
        let newFilters = _.cloneDeep(gridFilterState.value);
        _.forEach(dataFields, df => {
            if(!_.has(newFilters, df)) return;
            newFilters[df] = null;
        });
        gridFilterState.value = _.omitBy(newFilters, _.isNil);
        if(!applyResult) return;
        applyFilters();
    }

    function updateColumnFilter(dataField, value, isRange=false, operator=null, selected=null, applyResult=true) {
        let column = gridInstance?.columnOption(dataField);
        if(!column) return;
        setFilterValue(column, value, isRange, operator, selected);
        if(!applyResult) false;
        applyFilters();
    }

    function updateColumnFilterList(filterList) {
        if(!gridInstance) return;
        _.forEach(filterList, f => {
            let value = parseFilterStateValue(f);
            updateColumnFilter(f.dataField, value, _.parseBool(f.isRange), f.operator, f.selected, false);
        });
        applyFilters();
    }

    function fieldHasFilter(dataField=null) {
        if(_.isEmpty(dataField)) return false;
        return _.hasIn(gridFilterState.value, dataField);
    }

    return {
        gridFilterState,
        getConfiguredDataSource,
        applyFilters,
        getDataSource,
        getFilterValue,
        parseFilterState,
        setFilterValue,
        getFilterExpression,
        getColumnFilterExpression,
        getColumnFilterState,
        removeColumnFilter,
        removeColumnFilterList,
        updateColumnFilter,
        updateColumnFilterList,
        fieldHasFilter
    };

}