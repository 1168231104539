
import { default as dxConfig } from "devextreme/core/config";
import { DxTextBox, DxSelectBox, DxTagBox, DxAutocomplete, DxDateBox, DxPopover } from "devextreme-vue";
import SelectBox from "devextreme/ui/select_box";
import TagBox from "devextreme/ui/tag_box";

import * as components from "./lib-components/index";
import { closeDropDownOnDialogMouseEvent } from "./lib-components/utils";

import "devextreme/ui/data_grid";
import "devextreme/integration/jquery";

// install function executed by app.use()
const install = function installRqDevextremeVue(app, { licenseKey }) {

    dxConfig({ licenseKey });

    let dxDefaultOptions = {
        options: {
            inputAttr: { autocomplete: "chrome-off" },
            elementAttr: { class: "form-control" },
            searchEnabled: true,
            onFocusOut: closeDropDownOnDialogMouseEvent
        }
    };

    SelectBox.defaultOptions(dxDefaultOptions);
    TagBox.defaultOptions(dxDefaultOptions);

    const allComponents = {
        DxTextBox,
        DxSelectBox,
        DxTagBox,
        DxAutocomplete,
        DxDateBox,
        DxPopover,
        ...components
    };

    Object.entries(allComponents).forEach(([componentName, component]) => {
        app.component(componentName, component);
    });
};

// Create module definition for app.use()
export default install;

export { closeDropDownOnDialogMouseEvent };

// To allow individual component use, export components
// each can be registered via app.component()
export * from "./lib-components/index";
