
//TG -- This FocusOut handler is a workaround to issues where whatever internal
//      events that control DxSelectBox, DxTagBox, and DxDateBox dropdowns closing
//      are being swallowed up by the dialog component.
export function closeDropDownOnDialogMouseEvent(dxEvent) {
    if(!dxEvent) return;

    //If "dialogContainer" ends up null, then we're not in a dialog and things should behave as expected.
    let dialogContainer = dxEvent.element?.closest("#rq-dialog-container");
    if(!dialogContainer?.[0]) return;

    //If there's no relatedTarget, then the user likely clicked outside of the browser window.
    let relatedTarget = dxEvent.event?.relatedTarget || dxEvent.event?.originalEvent?.relatedTarget;
    if(!relatedTarget) return;

    //If thie target element is a DXButton, it's likely buttons within the dropdown
    //itself (OK, Cancel, etc.), in which case we don't want to close the dropdown prematurely
    let isDxButtonTarget = relatedTarget.classList?.contains("dx-button") || false;

    //If the target element is contained within an element with the class "dx-datebox-wrapper" and the
    //current component is a datebox, it's likely the target datebox popover, in which case we don't want
    //to close the dropdown prematurely
    let isDxDateBoxPopoverTarget = dxEvent.component?.NAME === "dxDateBox"
        && relatedTarget.closest(".dx-datebox-wrapper");

    let isOpened = dxEvent.component?.option("opened") || false;

    if(isDxButtonTarget || isDxDateBoxPopoverTarget || !isOpened) return;

    //close the dropdown
    dxEvent.component?.close();
}
