<template>

    <!-- <div v-if="isBoolType" class="row">
        <div class="col col-auto form-group">
            <RqCheckbox
                :automation_id="`chk_${automationIdPrefix}_bool_filter`"
                :disabled="isInputDisabled"
                v-model="localValue">{{ fieldLabel }}
            </RqCheckbox>
        </div>
    </div> -->
    <div class="row">
        <label class="col col-auto col-form-label me-auto">{{ fieldLabel }}:</label>
        <div class="col col-auto form-group-action">
            <b-btn
                v-show="isNumberType && !isTagBoxInput && !isSelectBoxInput"
                :id="`${automationIdPrefix}_type_toggle`"
                :automation_id="`btn_${automationIdPrefix}_type_toggle`"
                variant="link"
                class="px-0"
                :disabled="isInputDisabled"
                @click="useNumberRange=!useNumberRange">{{numberInputToggleDisplay}}</b-btn>
        </div>
    </div>
    <rq-date-range v-if="isDateFilter"
        v-model:start-date="rangeStart"
        v-model:end-date="rangeEnd"
        @change="onValueChanged"
        no-calendars
        show-calendar-drop-downs
        show-clear-button
        borderless
        overriddenStartDate
        overriddenEndDate
    />
    <rq-radio-group
        v-else-if="isBoolType"
        id="rdo_bool_filter"
        name="rdo_bool_filter"
        :automation_id="`rdo_${automationIdPrefix}_bool_filter`"
        :options="[
            { automation_id:`chk_${automationIdPrefix}_yes`, value: false, text: 'NO' },
            { automation_id:`chk_${automationIdPrefix}_no`, value: true, text: 'YES' },
            { automation_id:`chk_${automationIdPrefix}_all`, value: null, text: 'ALL' }
        ]"
        :disabled="isInputDisabled"
        v-model="localValue"
        @change="onValueChanged"
        inline
    />
    <dx-select-box
        v-else-if="isSelectBoxInput"
        ref="inputElement"
        :input-attr="$utils.idAttrs(`drp_${automationIdPrefix}`)"
        :data-source="lookupDataSource"
        :drop-down-options="{
            wrapperAttr: { id:`${filterContainerId}_dropdown_popup` }
        }"
        :display-expr="lookupDisplayExpr"
        :value-expr="lookupValueExpr"
        :show-clear-button="true"
        :no-data-text="filterOptions.noDataText"
        :placeholder="filterPlaceholder"
        :disabled="isInputDisabled"
        v-model="localValue"
        @value-changed="onValueChanged"
    />
    <dx-tag-box
        v-else-if="isTagBoxInput"
        ref="inputElement"
        :data-source="lookupDataSource"
        :input-attr="$utils.idAttrs(`tb_${automationIdPrefix}`)"
        :drop-down-options="{
            wrapperAttr: {
                id:`${filterContainerId}_dropdown_popup`,
                class: 'no-select-all'
            }
        }"
        :display-expr="lookupDisplayExpr"
        :value-expr="lookupValueExpr"
        :show-selection-controls="true"
        :show-clear-button="true"
        :max-displayed-tags="filterOptions.maxDisplayedTags"
        :show-drop-down-button="filterOptions.showDropDownButton"
        :show-data-before-search="filterOptions.showDataBeforeSearch"
        :no-data-text="filterOptions.noDataText"
        :min-search-length="filterOptions.minSearchLength"
        :placeholder="filterPlaceholder"
        :selected-items="selectedItemsValue"
        apply-value-mode="useButtons"
        :item-template="lookupItemTemplate"
        :disabled="isInputDisabled"
        :multiline="false"
        v-model="localValue"
        @multi-tag-preparing="onTagBoxMultiTagPreparing"
        @value-changed="onValueChanged">
        <template #rq-custom-item="{ data, index }">
            <component v-if="hasItemTemplate" :is="filterOptions.itemTemplate" :item="data" :index="index" />
        </template>
    </dx-tag-box>
    <template v-else>
        <div v-if="useNumberRange" class="input-range">
            <rq-number-range
                ref="inputElement"
                :id="`txt_${automationIdPrefix}`"
                :automation_id="`txt_${automationIdPrefix}`"
                :prefix="numberPrefix"
                :decimals="numberDecimals"
                :commas="numberRangeCommas"
                :disabled="isInputDisabled"
                v-model:start-value="rangeStart"
                v-model:end-value="rangeEnd"
                @change="onValueChanged"
                allow-nulls
            />
        </div>
        <input v-else
            ref="inputElement"
            :id="`txt_${automationIdPrefix}`"
            :automation_id="`txt_${automationIdPrefix}`"
            class="form-control"
            :type="isNumberType ? 'number' : 'text'"
            :placeholder="filterPlaceholder"
            :disabled="isInputDisabled"
            v-model="localValue"
            @change="onValueChanged">
    </template>
</template>

<script setup>
    import { ref, computed, inject, unref } from "vue";
    // import DxDateRangeBox from "devextreme-vue/date-range-box";
    import DxSelectBox from "devextreme-vue/select-box";
    import DxTagBox from "devextreme-vue/tag-box";
    import { RqColumnFilterOptions } from "../models";
    import { DATA_TYPES, FILTER_TYPES } from "../enums";
    import { useVModel } from "@vueuse/core";

    const props = defineProps({
        automation_id: { type: String, default: "" },
        label: { type: String, default: "" },
        dataField: { type: String, default: null },
        dataType: { type: String, default: null },
        format: { type: String, default: null },
        lookup: { type: Object, default: () => ({}) },
        filterOptions: { type: RqColumnFilterOptions, default: () => new RqColumnFilterOptions() },
        useRange: { type: Boolean, default: false },
        selectedItems: { type: Array, default: () => [] },
        modelValue: { type: [String, Boolean, Number, Array], default: null }
    });

    const emit = defineEmits([
        "update:modelValue",
        "update:useRange",
        "change"
    ]);

    const getConfiguredDataSource = () =>
        props.filterOptions.dataSource
            || props.lookup?.dataSource
            || props.lookup?.data
            || [];

    const localValue = useVModel(props, "modelValue", emit);
    const selectedItemsValue = useVModel(props, "selectedItems", emit);
    const useNumberRange = useVModel(props, "useRange", emit);

    const filterContainerId = _.uniqueId("rq-filter-container-");
    const inputElement = ref(null);
    const lookupDataSource = ref([]);
    const isInputDisabled = ref(false);

    const getRangeValueComputed = index => computed({
        get() { return localValue.value?.[index] || null; },
        set(val) {
            let rangeVal = _.isArray(localValue.value) && _.size(localValue.value) === 2
                ? localValue.value.slice()
                : _.fill(Array(2), null);
            rangeVal[index] = val;
            localValue.value = rangeVal;
        }
    });

    const rangeStart = getRangeValueComputed(0);
    const rangeEnd = getRangeValueComputed(1);

    const automationIdPrefix = computed(() => `fil_${props.automation_id}_${props.dataField}`);
    const fieldLabel = computed(() => props.label || _.startCase(props.dataField));
    const numberInputToggleDisplay = computed(() => `${useNumberRange.value ? "Contains" : "Range"} Filter`);
    const isDateType = computed(() => props.dataType === DATA_TYPES.date);
    const isDateTimeType = computed(() => props.dataType === DATA_TYPES.datetime);
    const isNumberType = computed(() => props.dataType === DATA_TYPES.number);
    const isBoolType = computed(() => props.dataType === DATA_TYPES.boolean);
    const isDateFilter = computed(() => isDateType.value || isDateTimeType.value);

    const filterType = computed(() => {
        let dataSource = getConfiguredDataSource();
        let filterType = props.filterOptions?.filterType;
        return filterType === FILTER_TYPES.default && !_.isEmpty(dataSource)
            ? FILTER_TYPES.tags
            : filterType;
    });
    const isSelectBoxInput = computed(() => filterType.value === FILTER_TYPES.select);
    const isTagBoxInput = computed(() => filterType.value === FILTER_TYPES.tags || (filterType.value === FILTER_TYPES.default && !_.isEmpty(lookupDataSource.value)));

    const isCurrencyFormat = computed(() => props.format === "currency");
    const numberPrefix = computed(() => isCurrencyFormat.value ? "$" : "");
    const numberDecimals = computed(() => isCurrencyFormat.value ? 2 : (props.filterOptions?.decimals || 0));
    const numberRangeCommas = computed(() => isCurrencyFormat.value || props.filterOptions?.commas);
    const lookupDisplayExpr = computed(() => props.filterOptions.displayExpr || props.lookup?.displayExpr || null);
    const lookupValueExpr = computed(() => props.filterOptions.valueExpr || props.lookup?.valueExpr || null);

    const defaultPlaceholder = computed(() => (isTagBoxInput.value || isSelectBoxInput.value) ? "Select..." : "");
    const filterPlaceholder = computed(() => props.filterOptions.placeholder || defaultPlaceholder.value);
    const hasItemTemplate = computed(() => !_.isEmpty(props.filterOptions.itemTemplate));
    const lookupItemTemplate = computed(() => hasItemTemplate.value ? "rq-custom-item" : "item");

    //This will be whatever the current value filter expression is and may not have been applied to the grid yet.
    const pendingFilterExpression = inject("pendingFilterExpression");

    function focusInput() {
        if(isDateFilter.value || isSelectBoxInput.value || isTagBoxInput.value) {
            inputElement.value?.instance?.focus?.();
        }
        else {
            inputElement.value?.focus?.();
        }
    }

    function setInputDisabled() {
        let options = _.cloneDeep(props.filterOptions);
        let filters = pendingFilterExpression.value;
        isInputDisabled.value = props.filterOptions.isInputDisabled({ options, filters });
    }

    function setDataSource() {
        let dataSource = getConfiguredDataSource();

        if((!props.filterOptions.persistSelectedItems && !props.filterOptions.dependencyDataField)
            || !_.isFunction(dataSource.load)) {
            lookupDataSource.value = _.isFunction(dataSource)
                ? lookupDataSource.value = dataSource()
                : dataSource;
            return;
        }

        let tbDataSource = _.cloneDeep(dataSource);
        tbDataSource.load = function(loadOptions) {
            let sanitizedItems = _.map(selectedItemsValue.value, _.toPlainObject);
            return dataSource.load(loadOptions, sanitizedItems, pendingFilterExpression.value);
        };
        lookupDataSource.value = tbDataSource;

        setInputDisabled();
    }

    function refresh(reset=false) {
        setInputDisabled();
        if(!inputElement.value || (!isSelectBoxInput.value && !isTagBoxInput.value)) return;
        let dxInstance = inputElement.value?.instance;
        if(!dxInstance) return;
        if(reset) {
            dxInstance.reset?.();
        }
        let ds = dxInstance.getDataSource?.();
        ds?.reload?.();
    }

    function onTagBoxMultiTagPreparing(e) {
        let items = e.selectedItems || [];
        if(items.length > props.filterOptions.maxDisplayedTags) {
            let tagVals = props.filterOptions.multiTagTemplate(items);
            let tagText = tagVals?.text;
            let tagTitle = tagVals?.title || tagText;

            if(_.isEmpty(tagText)) {
                e.cancel = true;
                return;
            }

            e.text = tagText;
            e.multiTagElement.attr("title", tagTitle);
        }
        else {
            e.cancel = true;
        }
    }

    function onValueChanged() {
        emit("change", { dataField: props.dataField });
    }

    setDataSource();

    defineExpose({
        dataField: props.dataField,
        focus: focusInput,
        refresh
    });
</script>
