<template>
    <div :id="elementIdAttr" :class="{ 'rq-range-input rq-date-range': true, 'rq-date-time': isDateTimeType, 'rq-with-calendars': showCalendars, 'rq-borderless': borderless, 'rq-borderless-input': borderlessInput }">
        <div v-if="showCalendars" class="rq-date-range-calendars">
            <div class="row">
                <div class="col col-auto me-auto">
                    <dx-calendar
                        :id="startDateCalendarId"
                        :max="maxDate"
                        :disabled="startDisabled"
                        v-model="startDateValue"
                    />
                </div>
                <div class="col col-auto">
                    <dx-calendar
                        :id="endDateCalendarId"
                        :min="minDate"
                        :disabled="endDisabled"
                        v-model="endDateValue"
                    />
                </div>
            </div>
        </div>
        <div :class="{ 'rq-range-inputs': true, 'rq-show-clear-button': showClearAllButton && hasValue}">
            <div class="row">
                <div :class="{ 'col form-group mb-0 pe-0':true, 'has-error': v$.startDateValue.required.$invalid }">
                    <rqdx-date-box
                        ref="dxDateBoxStart"
                        :id="startDateId"
                        :class="startDateInputClass"
                        width="100%"
                        :max="maxDate"
                        :type="type"
                        :show-analog-clock="showAnalogClock"
                        :show-drop-down-button="noCalendars && showCalendarDropDowns"
                        :disabled="startDisabled"
                        :use-mask-behavior="useMaskBehavior"
                        :display-format="displayFormat"
                        :show-clear-button="showClearButtons && !showClearAllButton"
                        :styling-mode="borderlessInput ? 'filled' : 'outlined'"
                        v-model:is-valid="startDxIsValid"
                        v-model="v$.startDateValue.$model"
                        @focus-out="onStartFocusOut"
                        default-button-display
                    />
                    <rq-validation-feedback
                        :offset="startFeedbackOffset"
                        no-field-label
                    >Start Date is required</rq-validation-feedback>
                </div>
                <div class="col-auto rq-range-delimiter">
                    <slot name="delimiter">
                        {{delimiterText}}
                    </slot>
                </div>
                <div :class="{ 'col form-group mb-0 ps-0':true, 'has-error': v$.endDateValue.required.$invalid }">
                    <rqdx-date-box
                        ref="dxDateBoxEnd"
                        :id="endDateId"
                        :class="endDateInputClass"
                        width="100%"
                        :min="minDate"
                        :type="type"
                        :show-analog-clock="showAnalogClock"
                        :show-drop-down-button="noCalendars && showCalendarDropDowns"
                        :disabled="endDisabled"
                        :use-mask-behavior="useMaskBehavior"
                        :display-format="displayFormat"
                        :show-clear-button="showClearButtons && !showClearAllButton"
                        :styling-mode="borderlessInput ? 'filled' : 'outlined'"
                        v-model:is-valid="endDxIsValid"
                        v-model="v$.endDateValue.$model"
                        @focus-out="onEndFocusOut"
                        default-button-display
                    />
                    <rq-validation-feedback
                        :offset="endFeedbackOffset"
                        no-field-label
                    >End Date is required</rq-validation-feedback>
                </div>
                <!-- <div v-if="inlineActions" class="col-auto inline-actions">
                    <slot name="inline-actions">
                        <i v-b-popover.v-danger.html.hover.top="inlineActionsSlotError" title="Slot Content Required" class="fas fa-debug font-2xl mt-2 text-danger"></i>
                    </slot>
                </div> -->
            </div>
            <rq-icon-button
                v-if="showClearAllButton"
                v-show="hasValue"
                icon="fas fa-times-circle"
                class="rq-clear-value"
                title="Clear Dates"
                v-rq-tooltip.hover.top
                @click="clearAll"
            />
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from "vue";
    import { DateTime } from "luxon";
    import DxCalendar from "devextreme-vue/calendar"
    import { LUXON_ISO_FORMAT, useDateHelpers } from "./DateUtil";
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";

    const MATCH_VALUE = { never: "never", beforeClear: "before-clear", always: "always" };
    const MATCH_FIELD = { both: "both", start: "start", end: "end" };

    export default {
        props: {
            id: { type: String, default:null },
            automation_id: { type: String, default: "" },
            startDate: { type: String, default: DateTime.now().toFormat("MM/dd/yyyy") },
            endDate: { type: String, default: DateTime.now().plus({ weeks: 1 }).toFormat("MM/dd/yyyy") },
            format: { type: String, default: LUXON_ISO_FORMAT },
            type: { type: String, default: "date" },
            showAnalogClock: { type: Boolean, default: false },
            inputClass: { type: String, default: "form-control" },
            startDateDisabled: { type: Boolean, default: false },
            endDateDisabled: { type: Boolean, default: false },
            startDateRequired: { type: Boolean, default: false },
            endDateRequired: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            borderless: { type: Boolean, default: false },
            borderlessInput: { type: Boolean, default: false },
            noCalendars: { type: Boolean, default: false },
            showClearButtons: { type: Boolean, default: false },
            showClearAllButton: { type: Boolean, default: false },
            showCalendarDropDowns: { type: Boolean, default: false },
            overriddenStartDate: { type: Boolean, default: false },
            overriddenEndDate: { type: Boolean, default: false },
            allowBackDate: { type: Boolean, default: false },
            useMaskBehavior: { type: Boolean, default: true },
            displayFormat: { type: String, default: "MM/dd/yyyy" },
            delimiterText: { type: String, default: "to" },
            matchValue: { type: String, default: MATCH_VALUE.never },
            matchField: { type: String, default: MATCH_FIELD.both },
            isValid: { type: Boolean, default: true }
            // inlineActions: { type: Boolean, default: false }
        },

        emits: [
            "update:isValid",
            "update:startDate",
            "update:endDate"
        ],

        components: {
            DxCalendar
        },

        setup(props, { emit }) {
            const v$ = useVuelidate();
            const {
                startDateValue,
                endDateValue,
                elementIdAttr,
                automationId,
                startDateCalendarId,
                startDateId,
                startDateAutomationId,
                endDateCalendarId,
                endDateId,
                endDateAutomationId,
                minDate,
                maxDate,
                isSameOrBefore,
                isSameOrAfter,
                clear
            } = useDateHelpers(props, emit);

            const matchEmptyValue = ref(false);

            //reflects DevEx internal validation state
            const startDxIsValid = ref(true);
            const endDxIsValid = ref(true);

            // const inlineActionsSlotError = ref("Slot content is required if <pre class='d-inline'>inlineActions=true</pre>. If visibility needs to be toggled while enabled, use the CSS class <pre class='d-inline'>.rq-range-inputs .inline-actions</pre>");

            onMounted(() => {
                matchEmptyValue.value = props.matchValue === MATCH_VALUE.never;
            });

            return {
                v$,
                startDateValue,
                endDateValue,
                matchEmptyValue,
                startDxIsValid,
                endDxIsValid,
                elementIdAttr,
                automationId,
                startDateCalendarId,
                startDateId,
                startDateAutomationId,
                endDateCalendarId,
                endDateId,
                endDateAutomationId,
                minDate,
                maxDate,
                isSameOrBefore,
                isSameOrAfter,
                clearAll: clear
            };
        },

        computed: {
            startDateBoxInstance() { return _.get(this, "$refs.startDate.instance", null); },
            endDateBoxInstance() { return _.get(this, "$refs.endDate.instance", null); },
            startDisabled() { return this.disabled || this.startDateDisabled; },
            endDisabled() { return this.disabled || this.endDateDisabled; },
            showCalendars() { return !this.noCalendars; },
            hasValue() { return !_.isNil(this.startDateValue) || !_.isNil(this.endDateValue); },
            matchValueBeforeClear() { return this.matchValue === MATCH_VALUE.beforeClear; },
            matchStartValue() { return this.matchField === MATCH_FIELD.both || this.matchField === MATCH_FIELD.start; },
            matchEndValue() { return this.matchField === MATCH_FIELD.both || this.matchField === MATCH_FIELD.end; },
            startDateInputClass() {
                return {
                    [this.inputClass]:true,
                    "overridden": this.overriddenStartDate
                };
            },
            endDateInputClass() {
                return {
                    [this.inputClass]:true,
                    "overridden": this.overriddenEndDate
                };
            },
            startFeedbackOffset() { return this.applyFeedbackOffset(this.startDateValue) ? -20 : 0; },
            endFeedbackOffset() { return this.applyFeedbackOffset(this.endDateValue) ? -5 : 0; },
            isDateTimeType() { return _.toLower(this.type) === "datetime"; },
            // calendarStartDate: {
            //     get() {
            //         if(_.isNil(this.startDateValue)) return new Date();
            //         return this.startDateValue;
            //     },
            //     set(val) { this.v$.startDateValue.$model = val; }
            // },
            // calendarEndDate: {
            //     get() {
            //         if(_.isNil(this.endDateValue)) return new Date();
            //         return this.endDateValue;
            //     },
            //     set(val) { this.v$.endDateValue.$model = val; }
            // }
        },

        watch: {
            startDate(newValue, oldValue) {
                if(_.isEmpty(newValue) && this.matchValueBeforeClear) {
                    this.matchEmptyValue = _.isEmpty(this.endDateValue);
                    return;
                }
                if(_.isNil(this.maxDate) || this.isSameOrBefore(newValue, this.endDateValue)) return;
                this.endDateValue = newValue;
                this.$nextTick(() => {
                    this.focusStartDate();
                });
            },
            endDate(newValue, oldValue) {
                if(_.isEmpty(newValue) && this.matchValueBeforeClear) {
                    this.matchEmptyValue = _.isEmpty(this.startDateValue);
                    return;
                }
                if(_.isNil(this.minDate) || this.isSameOrAfter(newValue, this.startDateValue)) return;
                this.startDateValue = newValue;
                this.$nextTick(() => {
                    this.focusEndDate();
                });
            },
            startDxIsValid(newValue, oldValue) {
                if(newValue === oldValue || newValue) return;
                this.resetDxValidation("startDateValue");
            },
            endDxIsValid(newValue, oldValue) {
                if(newValue === oldValue || newValue) return;
                this.resetDxValidation("endDateValue");
            },

            "v$.$error"(newValue) {
                this.$emit("update:isValid", newValue);
            }
        },

        validations() {
            const self = this;
            return {
                endDateValue: {
                    required: requiredIf(() => self.endDateRequired),
                    dxIsValid: () => true //self.endDxIsValid //temporarily bypassing dx interal validation due to bug with DxDateBox incorrectly emitting isValid=false
                },
                startDateValue: {
                    required: requiredIf(() => self.startDateRequired),
                    dxIsValid: () => true //self.startDxIsValid //temporarily bypassing dx interal validation due to bug with DxDateBox incorrectly emitting isValid=false
                },
            };
        },

        methods: {
            focus() {
                if(this.disabled) return;
                if(!this.startDateDisabled) {
                    this.focusStartDate();
                }
                else if(!this.endDateDisabled) {
                    this.focusEndDate();
                }
            },
            focusStartDate() { _.invoke(this, "$refs.dxDateBoxStart.focus"); },
            focusEndDate() { _.invoke(this, "$refs.dxDateBoxEnd.focus"); },
            allowMatchValue(targetRef, targetElement) {
                let dateBoxElement = _.invoke(this, `$refs.${targetRef}.dxInstance.$element`);
                if(_.isNil(dateBoxElement)) return false;
                let dateBoxInputElement = _.invoke(dateBoxElement, "find", "input.dx-texteditor-input");
                if(targetElement === dateBoxInputElement[0]) return false;
                return true;
            },
            syncRangeValues() {
                if(!this.matchEmptyValue) return;
                if(this.matchStartValue && _.isEmpty(this.startDateValue) && !_.isEmpty(this.endDateValue)) {
                    this.startDateValue = this.endDateValue;
                }
                if(this.matchEndValue && _.isEmpty(this.endDateValue) && !_.isEmpty(this.startDateValue)) {
                    this.endDateValue = this.startDateValue;
                }
            },
            onStartFocusOut(e) {
                const self = this;
                // Focus event fires before set occurs.
                self.$nextTick()
                    .then(() => {
                        self.syncRangeValues();
                    });
            },
            onEndFocusOut(e) {
                const self = this;
                // Focus event fires before set occurs.
                self.$nextTick()
                    .then(() => {
                        self.syncRangeValues();
                    });
            },
            applyFeedbackOffset(dateValue) {
                return this.showClearButtons && !this.showClearAllButton && _.isEmpty(dateValue);
            },
            resetDxValidation(fieldName) {
                let otherFieldName = fieldName === "startDateValue"
                    ? "endDateValue"
                    : "startDateValue";
                let currentValue = this[fieldName];
                this[fieldName] = this[otherFieldName];
                this.$nextTick(() => {
                    this[fieldName] = currentValue;
                });
            }
        }
    };

</script>
