import { DATA_TYPES } from "./enums";

export const parseFilterValue = (column, value) =>
    _.isNil(value)
        ? null
        : (column?.dataType === DATA_TYPES.date
            || column?.dataType === DATA_TYPES.datetime)
            ? new Date(value)
            : value;

export function parseRangeValues(column, values) {
    let startValue = null;
    let endValue = null;

    if(_.isArray(values)){
        startValue = parseFilterValue(column, values[0]);
        endValue = parseFilterValue(column, values[1]);
    }
    else
        startValue = values || null;
    return [startValue, endValue];
}

export function getStateFromExpr(filterExpr=[], startState={}) {
    if(_.isEmpty(filterExpr)) return {};
    const appendFieldExpr = (expr, state={}, opr="") => {
        if(_.isString(expr)) return;
        if(_.isString(expr[0])) {
            let name = expr[0],
                value = expr[2],
                filters = expr.slice();
            if(_.hasIn(state, name)) {
                if(_.isArray(state[name].value)) {
                    if(_.includes(state[name].value, value)) return state;
                    state[name].value.push(value);
                }
                else {
                    if(state[name].value === value) return state;
                    state[name].value = [state.value,value];
                }
                if(_.isArray(state[name].filters[0])) {
                    state[name].filters.push(opr);
                    state[name].filters.push(filters);
                }
                else {
                    state[name].filters = [state[name].filters.slice(), opr, filters];
                }
            }
            else {
                state[name] = { filters, value };
            }
        }
        else if(_.isArray(expr[0])) {
            _.forEach(expr, (childExpr,idx) => {
                if(_.isString(childExpr)) return;
                let prevOpr = idx > 0 ? expr[idx-1] : "";
                appendFieldExpr(childExpr, state, prevOpr);
            });
        }
        return state;
    };
    let resultState = _.cloneDeep(startState);
    return appendFieldExpr(filterExpr, resultState);
}

export function getRangeFilterExpression(column, values) {
    let parsedValues = parseRangeValues(column, values);
    let startValue = parsedValues[0];
    let endValue = parsedValues[1];
    if (_.isEqual(column.dataType, "datetime") && startValue != null) startValue.setHours(0,0,0,0);
    if (_.isEqual(column.dataType, "datetime") && endValue != null) endValue.setHours(23, 59, 59, 999);

    let filters = [];
    if(!_.isNil(startValue) || !_.isNil(endValue)) {

        if(_.isNil(startValue))
            filters = column.createFilterExpression(endValue, "<=");
        else if(_.isNil(endValue))
            filters = column.createFilterExpression(startValue, ">=");
        else
            filters = column.createFilterExpression([startValue, endValue], "between");
    }
    filters.columnIndex = column.index;
    filters.filterValues = [startValue, endValue];
    return filters;
}

export function getListFilterExpression(column, values, listOperator="or", valueOperator="=") {
    if(_.isEmpty(values)) return null;
    let filters = [];
    if(values.length > 1) {
        _.forEach(values, (v,i) => {
            if(i > 0) filters.push(listOperator);
            filters.push(column.calculateFilterExpression(v, valueOperator));
        });
    }
    else
        filters = column.calculateFilterExpression(values[0], valueOperator);
    filters.columnIndex = column.index;
    filters.filterValues = values.slice();
    return filters;
}

export function getCombinedFilterExpression(filterData) {
    if(_.isEmpty(filterData)) return [];
    let filters = [];
    let filterKeys = _.keys(filterData);
    if(filterKeys.length > 1) {
        _.forEach(filterData, v => {
            if(filters.length > 0) filters.push("and");
            filters.push(v.filters);
        });
    }
    else
        filters = filterData[filterKeys[0]].filters;
    return filters;
}

export function applyFilterState({ gridInstance=null, filterState={}, freshApply=false }) {
    if(_.isEmpty(gridInstance)) return [];

    if(freshApply) {
        gridInstance.clearFilter("filterValue");
    }

    let filterExpr = getCombinedFilterExpression(filterState);
    if(_.isEmpty(filterExpr))
        gridInstance.clearFilter();
    else
        gridInstance.filter(filterExpr);
    return filterExpr;

}

export default {
    parseFilterValue,
    parseRangeValues,
    getStateFromExpr,
    getRangeFilterExpression,
    getListFilterExpression,
    getCombinedFilterExpression,
    applyFilterState
};