export const DATA_TYPES = {
    string: "string",
    number: "number",
    date: "date",
    datetime: "datetime",
    boolean: "boolean",
    object: "object"
};

export const FILTER_TYPES = {
    default: "default",
    text: "text",
    tags: "tags",
    select: "select"
};

export const FILTER_MODE = {
    none: "none",
    default: "default", //column filters
    panel: "panel"
};

export const DEFAULT_TOOLBAR_ACTIONS = {
    EXPORT: "default-export",
    RESET: "default-reset",
    CLEAR_FILTERS: "default-clear-filters",
    COLUMN_CHOOSER: "default-column-chooser"
};

export const FOCUS_AFTER_MODE = {
    firstRow: "first-row",
    newRow: "new-row"
};