<template>
    <div :class="{
        'card rq-grid-filter-panel': true,
        'rq-toolbar-active': !hideToolbarOption
    }">
        <div v-if="!hideHeaderOption"
            :class="['card-header', headerClassOption]">
            <slot name="header"></slot>
        </div>
        <div v-if="!hideToolbarOption"
            :class="['card-toolbar', toolbarClassOption]">
            <RqCheckbox
                automation_id="chk_visible_cols_only"
                v-model="visibleOnly"
                switch>Visible Columns Only
            </RqCheckbox>
            <slot name="toolbar"></slot>
        </div>
        <div class="card-body">
            <RqScrollContainer
                :ps-options="psOptions"
                hide-top-button
                perfect-scrollbar>
                <div class="row">
                    <div v-for="(field, index) in filterList"
                        :key="field?.dataField"
                        :class="{
                            'col col-12 form-group': true,
                            'd-none': visibleOnly && !field.isColumnVisible
                        }">
                        <RqGridFilterField
                            ref="fieldComponentRefs"
                            :label="field.label"
                            :data-field="field.dataField"
                            :data-type="field.dataType"
                            :format="field.format"
                            :lookup="field.lookup"
                            :filter-options="field.filterOptions"
                            v-model:use-range="filterList[index].useNumberRange"
                            v-model:selected-items="filterList[index].selected"
                            v-model="filterList[index].value"
                            @change="onValueChange"
                        />
                    </div>
                </div>
                <div class="sticky-actions">
                    <RqButton
                        id="btn_grid_filter_panel_apply"
                        variant="link"
                        class="btn-action"
                        @click="onApply">Apply Filters
                    </RqButton>
                    <RqButton
                        id="btn_grid_filter_panel_reset"
                        variant="link"
                        class="btn-action"
                        @click="emit('reset')">Reset Filters
                    </RqButton>
                </div>
            </RqScrollContainer>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watch, nextTick, provide } from "vue";
    import RqGridFilterField from "./RqGridFilterField.vue";
    import { getStateFromExpr, getCombinedFilterExpression } from "../GridUtil";
    import { RqColumnFilterOptions, RqGridFilterOptions } from "../models";
    import { useRqFilters } from "../composables";
    import { useVModel } from "@vueuse/core";

    const props = defineProps({
        automation_id: { type: String, default: "" },
        gridInstance: { type: Object, default: () => ({}) },
        targetInactiveColumn: { type: String, default: null },
        filterOptions: { type: Object, default: () => new RqGridFilterOptions() },
        filterState: { type: Object, default: () => ({}) },
        clearBeforeNextApply: { type: Boolean, default: false }
    });

    const emit = defineEmits([
        "update:filterState",
        "update:clearBeforeNextApply",
        "update:visibleColumnsOnly",
        "filter-change",
        'reset'
    ]);

    const psOptions =  {
        maxScrollbarLength: 200,
        minScrollbarLength: 40,
        suppressScrollX: true,
        wheelPropagation: true,
        interceptRailY: styles => ({ ...styles, height: 0 })
    };

    const freshApply = useVModel(props, "clearBeforeNextApply", emit);
    const filterList = ref([]);
    const filterExpression = ref([]);
    const pendingFilterExpression = ref([]);
    const visibleOnly = ref(false);
    const fieldComponentRefs = ref([]);

    provide("pendingFilterExpression", pendingFilterExpression);

    const hideHeaderOption = computed(() => props.filterOptions?.hideHeader);
    const hideToolbarOption = computed(() => props.filterOptions?.hideToolbar);
    const headerClassOption = computed(() => props.filterOptions?.headerClass || "");
    const toolbarClassOption = computed(() => props.filterOptions?.toolbarClass || "");
    const visibleOnlyOption = computed(() => props.filterOptions?.visibleColumnsOnly);

    watch(visibleOnlyOption, (newVal, oldVal) => {
        if(newVal === oldVal || newVal === visibleOnly.value) return;
        visibleOnly.value = newVal;
    }, { immediate: true });

    watch(visibleOnly, (newVal, oldVal) => {
        if(newVal === oldVal) return;
        refreshFilterVisibility();
    });

    const {
        gridFilterState,
        getFilterValue,
        parseFilterState,
        applyFilters,
        removeColumnFilterList,
        updateColumnFilter,
        updateColumnFilterList
    } = useRqFilters(props, emit);

    function init() {
        let filterExpr = getCombinedFilterExpression(gridFilterState.value);
        filterExpression.value = filterExpr;
        pendingFilterExpression.value = filterExpr;
        buildFieldList();
    }

    function buildFieldList() {
        let columns = props.gridInstance?.option?.("columns");
        let result = [];
        _.forEach(columns, col => {
            let colOpts = props.gridInstance?.columnOption(col?.dataField);
            let filterOptions = new RqColumnFilterOptions(col?.rqFilter);
            if(!colOpts?.allowFiltering
                || colOpts?.dataField === props.targetInactiveColumn
                || filterOptions.isDisabled(_.cloneDeep(filterOptions))) return;
            let filterValues = getFilterValue(colOpts);
            result.push({
                label: colOpts?.caption || _.startCase(col.dataField),
                dataField: colOpts?.dataField,
                dataType: colOpts?.dataType || "string",
                format: colOpts?.format || null,
                lookup: colOpts?.lookup || null,
                useNumberRange: _.parseBool(filterValues?.useNumberRange),
                selected: filterValues?.selected || [],
                isColumnVisible: colOpts?.visible,
                value: filterValues?.value,
                filterOptions
            });
        });
        filterList.value = _.sortBy(result, f => f.filterOptions.panelFilterIndex);
    }

    function refreshFilterAtIndex({ index=-1, refreshValues=false }) {
        let filterInfo = filterList.value?.[index];
        if(!filterInfo) return;

        let colOpts = props.gridInstance?.columnOption(filterInfo.dataField);
        filterInfo.isColumnVisible = colOpts?.visible;

        if(!refreshValues) return;

        let filterValues = getFilterValue(colOpts);
        filterInfo.useNumberRange = _.parseBool(filterValues?.useNumberRange);
        filterInfo.selected = filterValues?.selected || [];
        filterInfo.value = filterValues?.value;
    }

    function refreshFilterVisibility() {
        if(_.isEmpty(filterList.value) || !visibleOnly.value) return;
        for(let index=0; index < filterList.value?.length; index++) {
            refreshFilterAtIndex({ index });
        }
    }

    function refreshFilterValues() {
        if(_.isEmpty(filterList.value)) return;
        for(let index=0; index < filterList.value?.length; index++) {
            refreshFilterAtIndex({ index, refreshValues: true });
        }
    }

    async function onApply() {
        let state = parseFilterState(filterList.value);
        gridFilterState.value = state;
        await nextTick();
        let filterExpr = applyFilters(freshApply.value);
        filterExpression.value = filterExpr;
        pendingFilterExpression.value = filterExpr;
        emitChange();
    }

    //only applies to SelectBox and TagBox filters; used to refresh cascading lookups/dependencies
    async function onValueChange({ dataField }) {
        let targetFilter = _.find(filterList.value, f => f?.filterOptions?.dependencyDataField === dataField);
        let targetDataField = targetFilter?.dataField;
        if(!targetDataField) return;

        await nextTick();

        let state = parseFilterState(filterList.value);
        pendingFilterExpression.value = getCombinedFilterExpression(state);

        await nextTick();

        let targetFieldComponent = _.find(fieldComponentRefs.value, f => f?.dataField === targetDataField);
        targetFieldComponent?.refresh?.(true);
    }

    async function reset(expr=[]) {
        gridFilterState.value = _.isEmpty(expr)
            ? {}
            : getStateFromExpr(expr);
        filterExpression.value = expr;
        await nextTick();
        emitChange();
        refreshFilterValues();
    }

    async function removeColumnFilter(dataField, applyUpdatedFilters=true) {
        removeColumnFilterList([dataField], applyUpdatedFilters);
        await nextTick();
        refreshFilterValues();
    }

    async function removeColumnFilters(dataFields, applyUpdatedFilters=true) {
        removeColumnFilterList(dataFields, applyUpdatedFilters);
        await nextTick();
        refreshFilterValues();
    }

    async function updateFilter(dataField, value, isRange=false, operator=null, selected=null, applyUpdatedFilters=true) {
        updateColumnFilter(dataField, value, isRange, operator, selected, applyUpdatedFilters);
        await nextTick();
        refreshFilterValues();
    }

    async function updateFilters(filterList) {
        updateColumnFilterList(filterList);
        await nextTick();
        refreshFilterValues();
    }

    async function emitChange() {
        emit("update:filterState", gridFilterState.value);
        emit("filter-change", { filterExpr: filterExpression.value });
    }

    init();

    defineExpose({
        removeColumnFilter,
        removeColumnFilters,
        updateFilter,
        updateFilters,
        refreshFilterVisibility,
        reset
    });

</script>