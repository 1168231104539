import { markRaw } from "vue";
import { FILTER_MODE } from "./enums";

const optsFromBool = boolVal => ({
    mode: boolVal ? FILTER_MODE.default : FILTER_MODE.none
});

export class RqGridFilterOptions {
    constructor(options) {
        let opts = _.isBoolean(options)
            ? optsFromBool(options)
            : (options || {});

        this.mode = _.has(FILTER_MODE, opts?.mode)
            ? opts.mode
            : FILTER_MODE.none;

        this.hideHeader = _.parseBool(opts.hideHeader);
        this.headerClass = opts.headerClass || "";

        //predicting the need for this functionality, so defaulting to true for now
        this.hideToolbar = _.parseBool(opts.hideToolbar, true);
        this.toolbarClass = opts.toolbarClass || "";

        //toggling this will dictate whether only filters corresponding with visible
        //columns will display;  If the toolbar/switch toggling visiblity is enabled,
        //this value will only serve as a default (it will not always reflect the value that switch is toggled to)
        this.visibleColumnsOnly = _.parseBool(opts.visibleColumnsOnly);
    }
}

export class RqColumnFilterOptions {
    constructor(options, dataField) {
        let opts = options || {};
        this.filterType = opts.filterType || "default";
        this.listOperator = opts.listOperator || "or";
        this.valueOperator = opts.valueOperator || "=";
        this.dataSource = opts.dataSource || null;
        this.displayExpr = opts.displayExpr || null;
        this.valueExpr = opts.valueExpr || null;
        this.noDataText = opts.noDataText || "No options available.";
        this.filterDataField = opts.filterDataField || dataField;
        this.decimals = _.parseNumber(opts.decimals, 0);
        this.minSearchLength = _.parseNumber(opts.minSearchLength, 0);
        this.maxDisplayedTags = _.parseNumber(opts.maxDisplayedTags, 1);
        this.placeholder = opts.placeholder || null;
        this.persistSelectedItems = _.parseBool(opts.persistSelectedItems, false);
        this.showDropDownButton = _.parseBool(opts.showDropDownButton, true);
        this.showDataBeforeSearch = _.parseBool(opts.showDataBeforeSearch, true);
        this.panelFilterIndex = _.parseNumber(opts.panelFilterIndex, -1);
        this.multiTagTemplate = _.isFunction(opts.multiTagTemplate) ? opts.multiTagTemplate : () => ({});

        //"markRaw" is used to prevent making a component obj a reactive element
        this.itemTemplate = _.isNil(opts.itemTemplate) ? null : markRaw(opts.itemTemplate);

        this.inputDisabled = _.isFunction(opts.inputDisabled) ? opts.inputDisabled : _.parseBool(opts.inputDisabled);
        this.inputDisabledMessage = opts.inputDisabledMessage || null;

        this.disabled = _.isFunction(opts.disabled) ? opts.disabled : _.parseBool(opts.disabled);
        this.disabledTooltip = opts.disabledTooltip || null;

        this.dependencyDataField = opts.dependencyDataField || null;
    }

    isInputDisabled(args) {
        return _.isFunction(this.inputDisabled) ? this.inputDisabled(args) : this.inputDisabled;
    }

    isDisabled(args) {
        return _.isFunction(this.disabled) ? this.disabled(args) : this.disabled;
    }
}

export class RqGridGroupingOptions {
    constructor(options) {
        let opts = _.isBoolean(options) ? { enabled: options, visible: options } : options || {};
        this.inputId = opts.inputId || _.uniqueId("tbx_grid_column_grouping_");
        this.automationId = opts.automationId || opts.automation_id || "tbx_grid_column_grouping";
        this.placeholder = opts.placeholder || "Group by...";
        this.size = opts.size || "sm";
        this.searchEnabled = _.parseBool(opts.searchEnabled, true);
        this.contextMenuEnabled = _.parseBool(opts.contextMenuEnabled, true);
        this.showDropDownButton = _.parseBool(opts.showDropDownButton, true);
        this.showClearButton = _.parseBool(opts.showClearButton, true);
        this.multiline = _.parseBool(opts.multiline);
        this.maxDisplayedTags = _.parseNumber(opts.maxDisplayedTags, null) || 4;
        this.maxSelectedGroups = _.parseNumber(opts.maxSelectedGroups, null) || 3;
        this.enabled = _.parseBool(opts.enabled);
        this.visible = _.parseBool(opts.visible);

        this.disabled = _.isFunction(opts.disabled) ? opts.disabled : _.parseBool(opts.disabled);
        this.disabledTooltip = opts.disabledTooltip || null;
    }

    get idAttrs() { return { id: this.inputId, automation_id: this.automationId }; }
    get small() { return this.size === "sm"; }

    isDisabled(args) {
        return _.isFunction(this.disabled) ? this.disabled(args) : this.disabled;
    }
}

export class RqGridColumnOptions {
    constructor(options) {
        let opts = options || {};

        //universal function returning a text string to highlight within the cell (uses a cellTemplate)
        this.highlightText = _.isFunction(opts.highlightText) ? opts.highlightText : null;

        //flag enabling the functionality to highlight text matching the ActionDatGrid.searchValue string within the cell (uses a cellTemplate)
        this.highlightSearchText = _.parseBool(opts.highlightSearchText);

        //flag enabling adding the "truncate-text" CSS class to an element surrounding the cell text (uses a cellTemplate)
        this.truncateCellText = _.parseBool(opts.highlightSearchText);
    }
}